import {
  Avatar,
  AvatarImg,
  ColorBlock,
  ColorBlockEmpty,
  ColorSection,
  GreenSegment,
  ItemStatisticBlock,
  MainBlockInformatin,
  MainName,
  ProgressBar,
  PurpleSegment,
  QaStatisticConteiner,
  QaStatisticSubConteiner,
  RatioBar,
  RatioStatisticConteiner,
  RatioStatisticItem,
  RatioStatisticList,
  Segment,
  SpanMainText,
  StatisticMainText,
  StatisticResult,
  StatisticSecondResult,
  StatisticSecondText,
  StatisticText,
  Text,
  TextBlockInformation,
  TextInformation,
  TimeInformation,
  TodayStatisticBlock,
  TodayStatisticConteiner,
  VerifiedStatisticConteiner,
  VerifiedStatisticItem,
  VerifiedStatisticList,
  WhiteSegment,
} from './QaStatistic.styled';

const QaStatistic = () => {
  const whiteWidth = 17;
  const greenWidth = 38;
  const purpleWidth = 45 + greenWidth;

  return (
    <QaStatisticConteiner>
      <QaStatisticSubConteiner>
        <TodayStatisticConteiner>
          <StatisticMainText>Сегодня</StatisticMainText>
          <TodayStatisticBlock>
            <ItemStatisticBlock>
              <StatisticText>Кол-во проверенных</StatisticText>
              <StatisticResult>76</StatisticResult>
            </ItemStatisticBlock>
            <ItemStatisticBlock>
              <StatisticSecondText>
                Среднее время на проверку
              </StatisticSecondText>
              <StatisticSecondResult>12m</StatisticSecondResult>
            </ItemStatisticBlock>
          </TodayStatisticBlock>
        </TodayStatisticConteiner>
        <RatioStatisticConteiner>
          <StatisticMainText>Соотношение</StatisticMainText>
          <ProgressBar>
            <WhiteSegment whiteWidth={whiteWidth}>
              <Text>{whiteWidth}%</Text>
            </WhiteSegment>
            <PurpleSegment purpleWidth={purpleWidth}>
              <Text>{purpleWidth - greenWidth}%</Text>
            </PurpleSegment>
            <GreenSegment greenWidth={greenWidth}>
              <Text>{greenWidth}%</Text>
            </GreenSegment>
          </ProgressBar>
          <RatioStatisticList>
            <RatioStatisticItem>
              <ColorBlockEmpty color={'#DFDAEB'} />
              Кол-во не проверенных
            </RatioStatisticItem>
            <RatioStatisticItem>
              <ColorBlock color={'#AE88FF'} />
              Кол-во отмеченых
            </RatioStatisticItem>
            <RatioStatisticItem>
              <ColorBlock color={'#94E170'} />
              Кол-во идеальных
            </RatioStatisticItem>
          </RatioStatisticList>
        </RatioStatisticConteiner>
        <VerifiedStatisticConteiner>
          <StatisticMainText>Проверено</StatisticMainText>
          <VerifiedStatisticList>
            <VerifiedStatisticItem>
              <MainBlockInformatin>
                {/* <AvatarImg /> */}
                <Avatar />
                <MainName>
                  Геннадий Вичер <SpanMainText>80%</SpanMainText>
                </MainName>
              </MainBlockInformatin>
              <TextBlockInformation>
                <TextInformation>Chat already been rew</TextInformation>
                <TimeInformation>9m</TimeInformation>
              </TextBlockInformation>
            </VerifiedStatisticItem>
            <VerifiedStatisticItem>
              <MainBlockInformatin>
                {/* <AvatarImg /> */}
                <Avatar />
                <MainName>
                  Геннадий Вичер<SpanMainText>80%</SpanMainText>
                </MainName>
              </MainBlockInformatin>
              <TextBlockInformation>
                <TextInformation>Chat already been rew</TextInformation>
                <TimeInformation>9m</TimeInformation>
              </TextBlockInformation>
            </VerifiedStatisticItem>
          </VerifiedStatisticList>
        </VerifiedStatisticConteiner>
      </QaStatisticSubConteiner>
    </QaStatisticConteiner>
  );
};

export default QaStatistic;
