// import Map from '../Map/Map';
import { useEffect, useState } from 'react';
import {
  AnaliticBlock,
  AnalyticsItemGen,
  AnalyticsListGen,
  ArrowImg,
  ArrowImgBot,
  ArrowImgTop,
  Avatar,
  ButtonAnalitics,
  ClosedBlock,
  CommentTextGen,
  GenCon,
  GeneralInfoBlock,
  GeneralInfoConteiner,
  Geolocation,
  GeolocationItem,
  LinkRequesterTickets,
  LocationItem,
  MainDataBlock,
  MainDataItem,
  MainDataList,
  MainDataSpan,
  MainDataText,
  MainText,
  MinusGen,
  PlusGen,
  PreChatFormBlock,
  PreChatFormItem,
  PreChatFormList,
  PreChatFormSpan,
  PreChatFormText,
  RequesterTicketsBlock,
  RequesterTicketsItem,
  RequesterTicketsList,
  RequesterTicketsText,
  SecondMainText,
  TechnicalDataBlock,
  TechnicalDataItem,
  TechnicalDataList,
  TechnicalDataSpan,
  TechnicalDataText,
  TextBlockGen,
  Time,
  TimeGeolocationBlock,
  TimeItem,
  UserBlock,
  UserEmail,
  UserImg,
  UserInfo,
  UserName,
  UserNameBlock,
} from './GeneralInfo.styled';

const GeneralInfo = () => {
  const testLocation = { latitude: 50.458, longitude: 30.5303 };

  const [analitics, setAnalitics] = useState(false);
  const [general, setGeneral] = useState(false);
  const [openTextIndex, setOpenTextIndex] = useState(null);

  const setAnother = (par, setPar) => {
    setPar(!par);
  };

  const toggleText = index => {
    setOpenTextIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const agent = [
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation1',
      decision: true,
    },
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation2',
      decision: false,
    },
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation3',
      decision: true,
    },
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation4',
      decision: true,
    },
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation5',
      decision: false,
    },
    {
      question: '1.Did the operator give his/her name?',
      explanation: 'explanation6',
      decision: true,
    },
  ];

  return (
    <GeneralInfoConteiner>
      <ButtonAnalitics onClick={() => setAnother(analitics, setAnalitics)}>
        Analytics {analitics === true ? <ArrowImgTop /> : <ArrowImgBot />}
      </ButtonAnalitics>
      {analitics === true ? (
        <AnaliticBlock>
          <AnalyticsListGen>
            {agent?.map((item, index) => (
              <AnalyticsItemGen onClick={() => toggleText(index)} key={index}>
                <TextBlockGen>
                  {item.question}
                  {item.decision === true ? <PlusGen /> : <MinusGen />}
                  {openTextIndex === index && (
                    <CommentTextGen>{item.explanation}</CommentTextGen>
                  )}
                </TextBlockGen>
              </AnalyticsItemGen>
            ))}
          </AnalyticsListGen>
        </AnaliticBlock>
      ) : (
        <></>
      )}

      <ButtonAnalitics onClick={() => setAnother(general, setGeneral)}>
        General info
        {general === true ? <ArrowImgTop /> : <ArrowImgBot />}
      </ButtonAnalitics>

      {general === true ? (
        <GenCon>
          <GeneralInfoBlock>
            <MainText>General info</MainText>
            <UserBlock>
              <UserInfo>
                {/* <UserImg /> */}
                <Avatar />
                <UserNameBlock>
                  <UserName>Артём Ковальцев</UserName>
                  <UserEmail>artemkov982@gmail.com</UserEmail>
                </UserNameBlock>
              </UserInfo>
            </UserBlock>
            <TimeGeolocationBlock>
              <GeolocationItem>
                <Geolocation />
                Kazan, Tatarstan People’s Republic
              </GeolocationItem>
              <TimeItem>
                <Time />
                21:43 user local time
              </TimeItem>
              <LocationItem>
                {/* <Map coordinates={testLocation} /> */}
              </LocationItem>
            </TimeGeolocationBlock>
          </GeneralInfoBlock>
          <MainDataBlock>
            <MainText>Main data</MainText>
            <MainDataList>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Device:</MainDataSpan> Mobile
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Language:</MainDataSpan> Ru
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Loyalty Level:</MainDataSpan> 4-Pro
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Name:</MainDataSpan> Артём
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Surname:</MainDataSpan> Ковальцев
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>e-mail:</MainDataSpan> artemkov982@gmail.com
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Project:</MainDataSpan> Gama
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Site:</MainDataSpan> https://gamacasino666.com
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>UserID:</MainDataSpan>{' '}
                  https://www2.fundist.org/ru/Users/Summary/589385128
                </MainDataText>
              </MainDataItem>
              <MainDataItem>
                <MainDataText>
                  <MainDataSpan>Tag:</MainDataSpan> PriVip
                </MainDataText>
              </MainDataItem>
            </MainDataList>
          </MainDataBlock>
          <PreChatFormBlock>
            <MainText>Pre-chat form</MainText>
            <PreChatFormList>
              <PreChatFormItem>
                <PreChatFormText>
                  <PreChatFormSpan>Name:</PreChatFormSpan> Артём Ковальцев
                </PreChatFormText>
              </PreChatFormItem>
              <PreChatFormItem>
                <PreChatFormText>
                  <PreChatFormSpan>e-mail:</PreChatFormSpan>{' '}
                  artemkov982@gmail.com
                </PreChatFormText>
              </PreChatFormItem>
            </PreChatFormList>
          </PreChatFormBlock>
          <TechnicalDataBlock>
            <MainText>Technical data</MainText>
            <TechnicalDataList>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>IP:</TechnicalDataSpan> 173.121.65.9
                </TechnicalDataText>
              </TechnicalDataItem>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>OS/Device:</TechnicalDataSpan> iOS (17.2.1)
                </TechnicalDataText>
              </TechnicalDataItem>
              <TechnicalDataItem>
                <TechnicalDataText>
                  <TechnicalDataSpan>Browser:</TechnicalDataSpan> Mobile Safari
                  (17.2)
                </TechnicalDataText>
              </TechnicalDataItem>
            </TechnicalDataList>
          </TechnicalDataBlock>
          <RequesterTicketsBlock>
            <MainText>Requester tickets</MainText>
            <SecondMainText>Recent tickets</SecondMainText>
            <RequesterTicketsList>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
              <RequesterTicketsItem>
                <ClosedBlock>CLOSED</ClosedBlock>
                <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
              </RequesterTicketsItem>
            </RequesterTicketsList>
            <SecondMainText>Archived tickets</SecondMainText>
            <RequesterTicketsText>
              <LinkRequesterTickets>Search Archive</LinkRequesterTickets> to
              view this requester’s archived tickets.
            </RequesterTicketsText>
          </RequesterTicketsBlock>
        </GenCon>
      ) : (
        <></>
      )}
    </GeneralInfoConteiner>
  );
};

export default GeneralInfo;
