import {
  AgentMessage,
  ChatConteiner,
  ChatInfo,
  CustomerMessage,
  GetChatButton,
  ImageContainer,
  InputContainer,
  InputValueBlock,
  MainCont,
  MessageConatiner,
  MessageContainerInfo,
  MessageConteiner,
  MessageConteinerItem,
  QaMessageAreaConteiner,
  SendButton,
  Shield,
  SystemMessage,
  TagBlock,
  TagButton,
  Telegram,
  TextAreaMainContainer,
  TextAreaQa,
  TextAreaToolsContainer,
  TextAreaToolsConteiner,
  TextAreaWrapper,
  TimeChat,
  UserChatImg,
  UserChatName,
  ValueButton,
  ValueButtonImg,
  ValueInput,
} from './QaMessageArea.styled';
import imgTest from '../../../images/userPhoto.png';
import Arrow from '../../../images/Arrow.png';
import Pensil from '../../../images/Pensil.png';
import { useState } from 'react';

const QaMessageArea = () => {
  const agent = {
    messages: [
      {
        author: {
          type: 'system',
          name: 'System',
        },
        text: 'This is a system message.',
        time: '10:10:56',
      },
      {
        author: {
          type: 'agent',
          name: 'Agent Smith',
        },
        text: 'Hello! How can I assist you today?',
        time: '10:12:15',
      },
      {
        author: {
          type: 'customer',
          name: 'John Doe',
        },
        text: 'I need help with my order.',
        time: '10:13:24',
      },
      {
        author: {
          type: 'agent',
          name: 'Agent Smith',
        },
        text: 'Sure, can you please provide me with your order number?',
        time: '10:14:08',
      },
      {
        author: {
          type: 'customer',
          name: 'John Doe',
        },
        text: 'My order number is 12345.',
        time: '10:15:45',
      },
    ],
  };

  const [isDisabled, setIsDisabled] = useState(true); // Управление состоянием

  const toggleDisabled = () => {
    setIsDisabled(prevState => !prevState); // Меняем значение на противоположное
  };

  return (
    <QaMessageAreaConteiner>
      <ChatConteiner>
        <GetChatButton>Получить Чат</GetChatButton>
        {/* {agent?.messages.map((item, index) => (
          <div key={index}>
            {item.author.type === 'system' && (
              <SystemMessage>{item.text} 10:10:56</SystemMessage>
            )}
            {item.author.type === 'agent' && (
              <MainCont side={'right'}>
                <MessageConatiner side={'right'}>
                  <MessageContainerInfo side={'right'}>
                    <TimeChat side={'right'}>10:10:56</TimeChat>
                    <UserChatName>{item.author.name}</UserChatName>
                  </MessageContainerInfo>
                  <AgentMessage>{item.text}</AgentMessage>
                </MessageConatiner>
                <ImageContainer>
                  <UserChatImg side={'right'} src={imgTest} />
                </ImageContainer>
              </MainCont>
            )}
            {item.author.type === 'customer' && (
              <MainCont side={'left'}>
                <ImageContainer>
                  <UserChatImg side={'left'} src={imgTest} />
                </ImageContainer>
                <MessageConatiner side={'left'}>
                  <MessageContainerInfo side={'left'}>
                    <UserChatName>{item.author.name}</UserChatName>
                    <TimeChat side={'left'}>10:10:56</TimeChat>
                  </MessageContainerInfo>
                  <CustomerMessage>{item.text}</CustomerMessage>
                </MessageConatiner>
              </MainCont>
            )}
          </div>
        ))}
        <TextAreaMainContainer>
          <TextAreaWrapper>
            <InputValueBlock>
              <InputContainer>
                <ValueInput type="text" disabled={isDisabled} />
                <ValueButton onClick={toggleDisabled}>
                  <img src={Pensil} alt="Pensil" />
                </ValueButton>
              </InputContainer>
            </InputValueBlock>
            <TextAreaQa />

            <TextAreaToolsContainer>
              <TagBlock>
                <TagButton>
                  <Shield />
                  Add tag
                </TagButton>
              </TagBlock>
              <SendButton>
                Send
                <Telegram src={Arrow} alt="Arrow" />
              </SendButton>
            </TextAreaToolsContainer>
          </TextAreaWrapper>
        </TextAreaMainContainer> */}
      </ChatConteiner>
    </QaMessageAreaConteiner>
  );
};

export default QaMessageArea;
