import styled from 'styled-components';
import { RxAvatar } from 'react-icons/rx';

export const QaStatisticConteiner = styled.div`
  margin-left: 101px;
  width: 280px;

`;

export const QaStatisticSubConteiner = styled.div`
  margin-left:16px;
`;

export const TodayStatisticConteiner = styled.div`
  margin-bottom: 15px;
`;

export const StatisticMainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #0a1019;

  margin-bottom: 20px;
`;

export const TodayStatisticBlock = styled.div`
  display: flex;
`;

export const ItemStatisticBlock = styled.div`
  width: 122px;
  height: 75px;

  border: 1px solid #dfdaeb;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 5px;
  border-radius: 5px;
`;

export const StatisticText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;

  color: #b7b0c7;
`;
export const StatisticSecondText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;

  margin-bottom: 8px;
  margin-top: 10px;

  color: #b7b0c7;
`;

export const StatisticResult = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;
  color: #1eaf69;
`;

export const StatisticSecondResult = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;

  margin: 0;

  color: #ae88ff;
`;

export const RatioStatisticConteiner = styled.div`
  margin-bottom: 15px;
`;

export const RatioStatisticList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const RatioStatisticItem = styled.li`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;

  color: #0a1019;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ColorBlock = styled.div`
  background: ${props => props.color};
  border-radius: 3px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
`;

export const ColorBlockEmpty = styled.div`
  border: 2px solid ${props => props.color};
  border-radius: 3px;
  width: 6px;
  height: 6px;
  margin-right: 10px;
`;

export const VerifiedStatisticConteiner = styled.div``;

export const VerifiedStatisticList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SpanMainText = styled.span`
font-family: 'Geologica';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
color: #1EAF69;
margin-left:12px;
`

export const VerifiedStatisticItem = styled.li`
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  margin-bottom: 15px;
  cursor: pointer;
  width: 250px;

  &:hover {
    border-color: #8a8a8a;
  }
`;

export const Avatar = styled(RxAvatar)`
  width: 24px;
  height: 24px;

  margin-right: 8px;
  color: #8a8a8a;
`;

export const MainBlockInformatin = styled.div`
  display: flex;
  align-items: center;
  margin-left: 13px;
`;

export const TextBlockInformation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
`;

export const AvatarImg = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;

export const MainName = styled.p`
display: flex;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  margin-bottom: 10px;
  color: #8a8a8a;
`;

export const TextInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-top: 0;

  color: #8a8a8a;

  margin-right: 14px;
`;

export const TimeInformation = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-top: 0;

  color: #8a8a8a;
`;

export const ProgressBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dfdaeb;
  border-radius: 10px;
  width: 250px; /* Total width of the progress bar */
  height: 70px; /* Height of the progress bar */
  overflow: hidden; /* Hides any overflowing content */
`;

export const PurpleSegment = styled.div`
  position: absolute; /* Absolute positioning within ProgressBar */
  z-index: 1; /* Below GreenSegment */
  background-color: #a67cf5;
  right: 0; /* Right aligned */
  width: ${props => props.purpleWidth}%; /* Dynamic width */
  height: 100%;
  border-radius: 10px 10px 10px 10px; /* Full border-radius */
`;

export const GreenSegment = styled.div`
  position: absolute;
  z-index: 2; /* Above PurpleSegment */
  top: 0;
  right: 0; /* Right aligned */
  background-color: #8de58f;
  width: ${props => props.greenWidth}%; /* Dynamic width */
  height: 100%;
  border-radius: 10px 10px 10px 10px;
`;

export const WhiteSegment = styled.div`
  position: relative;
  z-index: 3; /* Above all segments */
  background-color: white;
  width: ${props => props.whiteWidth}%; /* Dynamic width */
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: black;
`;

export const Text = styled.div`
  position: absolute; /* Positioned over the entire ProgressBar */
  z-index: 4;
font-family: 'Geologica';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 12px;
color: #000000;


  text-align: center;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
`;
